var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-row',{staticClass:"px-4 py-2",attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.durations,"item-text":"label","item-value":"value","label":"Duration","outlined":"","dense":""},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}})],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"2"}},[_c('ma-date-picker',{attrs:{"label":"From","past":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1"}},[(_vm.validClean)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","elevation":"0","small":"","rounded":"","depressed":"","fab":""},on:{"click":_vm._clearFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,false,527521509)},[_c('span',[_vm._v("Clear Date filters")])])]:_vm._e()],2)],1),[_c('v-row',{staticClass:"pa-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.statistics,"options":_vm.options,"loading":_vm.loading,"footer-props":_vm.footerProps,"server-items-length":_vm.total,"item-key":"uuid"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.phone)?[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.makeCallV(item.phone)}}},[_vm._v(" "+_vm._s(item.phone)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPhone(item.phone)}}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy Phone")])])],1)])]:[_vm._v(" - ")]]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(_vm._s(item.last_name))])]}},{key:"item.statusNom",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1 cursor-pointer",style:({ color: _vm.colorShow(item) }),on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(" "+_vm._s(_vm.statusShowReal(item))+" ")])]}},{key:"item.sourceNom",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold cursor-pointer",style:({ color: _vm.colorShowS(item) }),on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(_vm._s(item.sourceNom ? item.sourceNom.name : "-"))])]}}],null,true)})],1)])],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }