var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-toolbar',{staticClass:"primary",attrs:{"tag":"div","flat":"","dark":"","tile":""}},[_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s("Status Changes"))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){return _vm.$emit('closedialog')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.statistics,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.showDetails},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.client ? item.client.fullname : "-")+" ")])]}},{key:"item.beforePolizeStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1 font-weight-bold",style:({ color: _vm.colorShow(item.beforePolizeStatus) })},[_vm._v(" "+_vm._s(_vm.statusShow(item.beforePolizeStatus))+" ")])]}},{key:"item.polizeStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1 font-weight-bold",style:({ color: _vm.colorShow(item.polizeStatus) })},[_vm._v(" "+_vm._s(_vm.statusShow(item.polizeStatus))+" ")])]}},{key:"item.gold",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:({ color: '#EDC43D' })},[_vm._v(" "+_vm._s(item.gold)+" ")])]}}],null,true)})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }