var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-container',[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.statistics,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.bronze",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:({ color: '#CD7F32' })},[_vm._v(" "+_vm._s(item.bronze)+" ")])]}},{key:"item.silver",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:({ color: '#C0C0C0' })},[_vm._v(" "+_vm._s(item.silver)+" ")])]}},{key:"item.gold",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold",style:({ color: '#EDC43D' })},[_vm._v(" "+_vm._s(item.gold)+" ")])]}},{key:"item.policies",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.showDetails(item)}}},[_vm._v(" "+_vm._s(item.policies)+" ")])]}}],null,true)}),_c('v-dialog',{attrs:{"scrollable":"","fullscreen":"","persistent":"","overlay":true,"transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog && _vm.currentItem)?[_c('policies-view',{attrs:{"typeData":'company',"idSearch":_vm.currentItem,"nameItem":_vm.nameItem},on:{"closeDialog":_vm.closeDialog}})]:_vm._e()],2)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }