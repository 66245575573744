<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card :disabled="loading" :loading="loading">
      <v-container>
        <v-layout>
          <v-radio-group row v-model="filterBy">
            <v-radio
              label="DOB/ Name/ Last Name"
              value="dob_name_lastname"
            ></v-radio>
            <v-radio label="Name/ Last Name" value="name_lastname"></v-radio>
            <v-radio label="Policy Number" value="policy_number"></v-radio>
            <v-radio label="Email" value="by_email"></v-radio>
            <v-radio label="Phone" value="phone_number"></v-radio>
          </v-radio-group>
        </v-layout>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="duplicateClients"
          :footer-props="footerProps"
          :options.sync="options"
          :server-items-length="total"
          class="elevation-1"
        >
          <template v-slot:[`item.phone`]="{ item }">
            <template v-if="item.phone">
              <div class="d-flex">
                <div class="cursor-pointer" @click="makeCallV(item.phone)">
                  {{ item.phone }}
                </div>
                <div class="mx-1">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        @click="copyPhone(item.phone)"
                        v-on="on"
                        small
                        color="info"
                        >mdi-content-copy</v-icon
                      >
                    </template>
                    <span>Copy Phone</span>
                  </v-tooltip>
                </div>
              </div>
            </template>
            <template v-else> - </template>
          </template>
          <template v-slot:[`item.dob`]="{ item }">
            {{ getDOB(item.dob) }}
          </template>
          <template v-slot:[`item.fullname`]="{ item }">
            {{ item.fullname ? item.fullname : "" }}
          </template>
          <template v-slot:[`item.counts`]="{ item }"
            >{{
              item.policy_number_count
                ? item.policy_number_count
                : item.dob_count
                ? item.dob_count
                : item.fullname_count
            }}
          </template>
          <template v-slot:[`item.actions`]="{ item }"
            ><template v-if="item.relations || item.clients">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="success"
                    icon
                    @click="showRelations(item)"
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </template>
                <span>Show Details</span>
              </v-tooltip>
            </template>
          </template>
        </v-data-table>
        <v-dialog
          v-model="relationShow.dialog"
          persistent
          :overlay="false"
          max-width="900px"
          transition="dialog-transition"
        >
          <v-card>
            <v-toolbar class="primary" tag="div" flat dark tile>
              <div class="text-h6">{{ "Relationships" }}</div>
              <v-spacer></v-spacer>
              <v-btn color="white" icon @click="closeShow"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-toolbar>

            <v-card-text class="mt-3">
              <v-tabs v-model="tabsD">
                <v-tab href="#clients">Clients</v-tab>
                <v-tab href="#relations">Relations</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabsD">
                <v-tab-item value="clients">
                  <template
                    v-if="relationShow.item && relationShow.item.clients"
                  >
                    <v-data-table
                      :headers="headersD"
                      :items="relationShow.item.clients"
                      single-expand
                      show-expand
                      item-key="index"
                      :expanded.sync="expandedC"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.phone`]="{ item }">
                        <template v-if="item.phone">
                          <div class="d-flex justify-center">
                            <div
                              class="cursor-pointer"
                              @click="makeCallV(item.phone)"
                            >
                              {{ item.phone }}
                            </div>
                            <div class="mx-1">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    @click="copyPhone(item.phone)"
                                    v-on="on"
                                    small
                                    color="info"
                                    >mdi-content-copy</v-icon
                                  >
                                </template>
                                <span>Copy Phone</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </template>
                        <template v-else> - </template>
                      </template>
                      <template v-slot:[`item.dob`]="{ item }">
                        {{ getDOB(item.dob) }}
                      </template>

                      <template v-slot:[`item.actions`]="{ item }"
                        ><template v-if="item">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                color="success"
                                icon
                                @click="gotoClient(item.uuid)"
                                ><v-icon>mdi-account</v-icon></v-btn
                              >
                            </template>
                            <span>Show Client Details</span>
                          </v-tooltip>
                        </template>
                      </template>

                      <template v-slot:expanded-item="{ headers }">
                        <td :colspan="headers.length">
                          <template v-if="loadingClient">
                            <div class="d-flex justify-center pa-3">
                              <v-progress-circular
                                :size="50"
                                :width="5"
                                color="primary"
                                indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                          <template v-if="clientDetails && !loadingClient">
                            <v-container>
                              <v-row no-gutters>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Full name
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      clientDetails.fullname
                                        ? clientDetails.fullname
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Email
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      clientDetails.email
                                        ? clientDetails.email
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Phone
                                  </div>
                                  <div class="text-body-1">
                                    <template v-if="clientDetails.homePhone">
                                      <div class="d-flex">
                                        <div
                                          class="cursor-pointer"
                                          @click="
                                            makeCallV(clientDetails.homePhone)
                                          "
                                        >
                                          {{ clientDetails.homePhone }}
                                        </div>
                                        <div class="mx-1">
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                              <v-icon
                                                @click="
                                                  copyPhone(
                                                    clientDetails.homePhone
                                                  )
                                                "
                                                v-on="on"
                                                small
                                                color="info"
                                                >mdi-content-copy</v-icon
                                              >
                                            </template>
                                            <span>Copy Phone</span>
                                          </v-tooltip>
                                        </div>
                                      </div>
                                    </template>
                                    <template v-else> - </template>
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Created by
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      clientDetails.createdBy
                                        ? clientDetails.createdBy.fullname
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                              </v-row>
                              <v-divider class="my-2" />
                              <v-row no-gutters>
                                <v-col cols="12" sm="2">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    SSN
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      clientDetails.ssn
                                        ? "XXX-XX-" + clientDetails.ssn
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="2">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    DOB
                                  </div>
                                  <div class="text-body-1">
                                    {{ birthDate }}
                                  </div>
                                </v-col>

                                <v-col cols="12" sm="2">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Gender
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      clientDetails.isMale ? "Male" : "Female"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="2">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Source
                                  </div>
                                  <div class="text-body-1">
                                    <template v-if="clientDetails">
                                      <span
                                        v-bind:style="{
                                          color: colorShowSource,
                                        }"
                                        class="font-weight-bold"
                                      >
                                        {{
                                          clientDetails.sourceNom
                                            ? clientDetails.sourceNom.name
                                            : "-"
                                        }}
                                      </span>
                                    </template>
                                    <template v-else> - </template>
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="2">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Preferred Language
                                  </div>
                                  <div class="text-body-1 text-capitalize">
                                    {{
                                      clientDetails.preferLanguage
                                        ? clientDetails.preferLanguage.toLowerCase()
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="2">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Contact preference
                                  </div>
                                  <div class="text-body-1 text-capitalize">
                                    {{
                                      clientDetails.contactPreference
                                        ? clientDetails.contactPreference.toLowerCase()
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                              </v-row>

                              <v-divider class="my-2" />
                              <v-row class="mb-2" no-gutters>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Status
                                  </div>
                                  <div
                                    class="text-body-1"
                                    v-bind:style="{ color: colorShow }"
                                  >
                                    {{ getStatusShow }}
                                  </div>
                                </v-col>

                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Company
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      clientDetails.company
                                        ? clientDetails.company.name
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Company State
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      clientDetails.companyState
                                        ? clientDetails.companyState.name
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Binder pay
                                  </div>
                                  <div class="text-body-1 px-2">
                                    {{ clientDetails.binderDay ? "Yes" : "No" }}
                                  </div>
                                </v-col>
                              </v-row>
                              <v-divider class="my-2"></v-divider>
                              <v-row class="mb-2" no-gutters>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Quoted Date
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      clientDetails.quotedDate
                                        ? formatDate(clientDetails.quotedDate)
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Metal Plan
                                  </div>
                                  <div
                                    class="text-body-1 font-weight-bold"
                                    v-bind:style="{ color: colorPolicy }"
                                  >
                                    {{
                                      clientDetails.policyPlan
                                        ? clientDetails.policyPlan
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Premium
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      !clientDetails.premium
                                        ? 0
                                        : clientDetails.premium | currency
                                    }}
                                  </div>
                                </v-col>
                              </v-row>
                              <v-divider class="my-2"></v-divider>
                              <v-row no-gutters>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Policy Number
                                  </div>
                                  <div class="text-body-1">
                                    {{
                                      clientDetails.polizeNumber
                                        ? clientDetails.polizeNumber
                                        : "-"
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Effective Date
                                  </div>
                                  <div class="text-body-1">
                                    {{ efectiveDate }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Total Members
                                  </div>
                                  <div class="text-body-1 px-4">
                                    {{
                                      clientDetails.members
                                        ? clientDetails.members
                                        : 0
                                    }}
                                  </div>
                                </v-col>
                                <v-col cols="12" sm="3">
                                  <div
                                    class="
                                      text-caption
                                      font-weight-blod
                                      text--secondary
                                    "
                                  >
                                    Monthly pay
                                  </div>
                                  <div class="text-body-1 px-2">
                                    {{
                                      clientDetails.monthlyPay
                                        ? clientDetails.monthlyPay
                                        : 0 | currency
                                    }}
                                  </div>
                                </v-col>
                              </v-row>
                              <v-divider class="my-2" />
                            </v-container>
                          </template>
                        </td>
                      </template> </v-data-table
                  ></template>
                </v-tab-item>
                <v-tab-item value="relations">
                  <template
                    v-if="relationShow.item && relationShow.item.relations"
                  >
                    <v-data-table
                      :headers="headersR"
                      :items="relationShow.item.relations"
                      single-expand
                      show-expand
                      item-key="index"
                      :expanded.sync="expandedR"
                      :items-per-page="10"
                      class="elevation-1"
                    >
                      <template v-slot:[`item.phone`]="{ item }">
                        <template v-if="item.phone">
                          <div class="d-flex justify-center">
                            <div
                              class="cursor-pointer"
                              @click="makeCallV(item.phone)"
                            >
                              {{ item.phone }}
                            </div>
                            <div class="mx-1">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    @click="copyPhone(item.phone)"
                                    v-on="on"
                                    small
                                    color="info"
                                    >mdi-content-copy</v-icon
                                  >
                                </template>
                                <span>Copy Phone</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </template>
                        <template v-else> - </template>
                      </template>
                      <template v-slot:[`item.patientcreateAt`]="{ item }">
                        {{ getDOBR(item.patientcreateAt) }}
                      </template>
                      <template v-slot:[`item.relation`]="{ item }">
                        {{ item.relation ? item.relation.employee : "" }}
                      </template>

                      <template v-slot:[`item.actions`]="{ item }"
                        ><template v-if="item">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                v-on="on"
                                color="success"
                                icon
                                @click="gotoClient(item.patientUuid)"
                                ><v-icon>mdi-account</v-icon></v-btn
                              >
                            </template>
                            <span>Show Client Details</span>
                          </v-tooltip>
                        </template>
                      </template>
                      <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length">
                          <template v-if="item.relation">
                            <v-row>
                              <v-col
                                cols="12"
                                sm="4"
                                class="d-flex flex-column"
                              >
                                <label for="">Employee:</label>
                                <span>{{ item.relation.employee }}</span>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-else>
                            <div>
                              <div class="text-center">
                                {{ "Does not have relationships" }}
                              </div>
                            </div>
                          </template>
                        </td>
                      </template>
                    </v-data-table>
                  </template>
                  <template v-else>
                    <div class="text-center">
                      {{ "Does not have relationships" }}
                    </div>
                  </template>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container></v-card
    >
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";
import { PolicyStatus } from "@/utils/enums/policystatus";
import moment from "moment";
import { funtionsPhone } from "@/utils/mixins";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "duplicate-clients-statistics",
  mixins: [funtionsPhone],
  data() {
    return {
      duplicateClients: [],
      filterBy: "dob_name_lastname",
      loading: false,
      footerProps: {
        "items-per-page-options": [15, 25, 50, 500],
      },
      options: {},
      total: 0,
      relationShow: {
        dialog: false,
        item: null,
      },
      expandedR: [],
      expandedC: [],
      clientDetails: null,
      loadingClient: false,

      isAdmins: false,

      tabsD: "duplicate",
      headersD: [
        {
          text: "Fullname",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Phone",
          align: "center",
          sortable: false,
          value: "phone",
        },
        {
          text: "Email",
          align: "center",
          sortable: false,
          value: "email",
        },
        {
          text: "DOB",
          align: "center",
          sortable: false,
          value: "dob",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "data-table-expand",
        },

        // { text: "", value: "data-table-expand" },
      ],
      headersR: [
        {
          text: "Fullname",
          align: "start",
          sortable: false,
          value: "patientFullName",
        },
        {
          text: "Created At",
          align: "center",
          sortable: false,
          value: "patientcreateAt",
        },
        {
          text: "Relationship",
          align: "center",
          sortable: false,
          value: "relation",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "actions",
        },
        // { text: "", value: "data-table-expand" },
      ],
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getDuplicate();
      }
    },
    filterBy: {
      handler() {
        this.getDuplicate();
      },
    },
    expandedC(val) {
      if (val.length != 0) {
        this.getPatientData(val[0].uuid);
      }
    },
  },

  computed: {
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor"]),
    policyStates() {
      const states = this.isAdmins
        ? [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "PAYMENT_PENDING", label: "Payment Pending" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ]
        : [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ];
      return states;
    },
    colorPolicy() {
      if (this.clientDetails == null) {
        return "#000";
      }
      const status = this.clientDetails.policyPlan;
      if (status != null && status != undefined) {
        switch (status) {
          case "GOLD":
            return "#EDC43D";
          case "SILVER":
            return "#C0C0C0";
          case "BRONZE":
            return "#CD7F32";

          default:
            return "#000";
        }
      }
      return "#000";
    },
    colorShowSource() {
      if (this.clientDetails && this.clientDetails.sourceNom) {
        return this.clientDetails.sourceNom.color;
      }
      return "#000";
    },
    colorShow() {
      if (this.clientDetails == null) {
        return "#000";
      }
      const status = this.clientDetails.polizeStatus;
      if (status != null && status != undefined) {
        switch (status) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "POLICY_NEW":
            return "#80FF00";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "POLICY_BLACKLIST":
            return "#270E41";
          default:
            return "#000";
        }
      }
      return "#000";
    },

    birthDate() {
      if (this.clientDetails == null) {
        return "-";
      }
      if (!this.clientDetails.birthDate) {
        return "-";
      }
      const date = moment(this.clientDetails.birthDate);

      return date.format("MM/DD/YYYY");
    },

    efectiveDate() {
      if (this.clientDetails == null) {
        return "-";
      }
      if (!this.clientDetails.effectiveDate) {
        return "-";
      }
      const date = this.clientDetails.effectiveDate.split("T")[0];
      const y = date.split("-")[0];
      const m = date.split("-")[1];
      const d = date.split("-")[2];

      return `${m}/${d}/${y}`;
    },

    getStatusShow() {
      if (this.clientDetails == null || this.clientDetails == undefined) {
        return "-";
      }
      const p = this.clientDetails.polizeStatus;
      if (p) {
        const st = PolicyStatus[p];
        return st;
      }

      return "-";
    },

    headers() {
      const h = [
        {
          text: "Fullname",
          align: "start",
          sortable: false,
          value: "fullname",
        },
        {
          text: "DOB",
          align: "center",
          sortable: false,
          value: "dob",
        },
        {
          text: "Policy Number",
          align: "center",
          sortable: false,
          value: "policy_number",
        },
        {
          text: "Counts",
          align: "center",
          sortable: false,
          value: "counts",
        },
        {
          text: "",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ];
      if (this.filterBy == "by_email") {
        return [
          {
            text: "Email",
            align: "start",
            sortable: false,
            value: "email",
          },
          {
            text: "Counts",
            align: "center",
            sortable: false,
            value: "email_count",
          },
          {
            text: "",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ];
      }
      if (this.filterBy == "phone_number") {
        return [
          {
            text: "Phone",
            align: "start",
            sortable: false,
            value: "phone",
          },
          {
            text: "Counts",
            align: "center",
            sortable: false,
            value: "phone_count",
          },
          {
            text: "",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ];
      }
      return h;
    },
  },
  methods: {
    getPatientData(uuid) {
      this.loadingClient = true;
      getAPI(`/patient/getPatient/${uuid}`)
        .then((res) => {
          this.loadingClient = false;
          this.clientDetails = res.data;
        })
        .catch((err) => {
          this.loadingClient = false;
          let mess = err.response.data.message.toString();
          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },

    getDuplicate() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 100;
      const range = {
        limit: itmper,
        offset: (page - 1) * itmper,
        filterByNameLastName: this.filterBy == "name_lastname" ? true : false,
        filterByPolicyNumber: this.filterBy == "policy_number" ? true : false,
        filterByPhone: this.filterBy == "phone_number" ? true : false,
        filterByEmail: this.filterBy == "by_email" ? true : false,
      };
      this.loading = true;
      getAPI
        .post("/reports/summary-duplicated-clients", range)
        .then((res) => {
          this.loading = false;
          this.duplicateClients = res.data.result;
          this.total = res.data.count;
        })
        .catch((err) => {
          this.loading = false;
          let mess = err.response.data.message.toString();
          notifyError(err.response.data, `An error occurred: ${mess}`);
        });
    },
    showRelations(item) {
      let it = item;
      if (it.relations) {
        it.relations = it.relations.map((i, index) => {
          return { ...i, index };
        });
      }
      if (it.clients) {
        it.clients = it.clients.map((i, index) => {
          return { ...i, index };
        });
      }
      if (this.isSuper || this.isAdmin || this.isSupervisor) {
        this.isAdmins = true;
      }

      this.relationShow = { dialog: true, item: it };
    },
    closeShow() {
      this.expandedC = [];
      this.expandedR = [];
      this.clientDetails = null;
      this.relationShow = { dialog: false, item: null };
    },

    getDOB(value) {
      if (value == null || value == undefined) {
        return "";
      }

      const x = moment(value).format("MM/DD/YYYY");

      return x;
    },
    getDOBR(value) {
      if (value == null || value == undefined) {
        return "";
      }

      const x = moment(value).format("MM/DD/YYYY HH:mm");

      return x;
    },
    gotoClient(patient) {
      this.$router.push({
        name: "Patient ddetails",
        params: { uuid: patient },
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
