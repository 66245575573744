<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="payments"
      :footer-props="footerProps"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-row class="px-3">
          <v-col cols="12" sm="2" class="px-4 pt-4">
            <v-select
              :items="yearShow"
              v-model="year"
              outlined
              dense
              label="Filter by Year"
            ></v-select>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { notifyError } from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import moment from "moment";
export default {
  name: "payment-statistic",
  data() {
    return {
      payments: [],
      year: moment().format("YYYY"),
      loading: false,
      footerProps: {
        "items-per-page-options": [100, 500, 1000],
      },
    };
  },
  computed: {
    yearShow() {
      return [
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
      ];
    },
    headers() {
      return [
        {
          text: "Company",
          align: "start",
          sortable: false,
          value: "company",
        },
        {
          text: "January",
          align: "center",
          sortable: false,
          value: "january",
        },
        {
          text: "February",
          align: "center",
          sortable: false,
          value: "february",
        },
        {
          text: "March",
          align: "center",
          sortable: false,
          value: "march",
        },
        {
          text: "April",
          align: "center",
          sortable: false,
          value: "april",
        },
        {
          text: "May",
          align: "center",
          sortable: false,
          value: "may",
        },
        {
          text: "June",
          align: "center",
          sortable: false,
          value: "june",
        },
        {
          text: "July",
          align: "center",
          sortable: false,
          value: "july",
        },
        {
          text: "August",
          align: "center",
          sortable: false,
          value: "august",
        },
        {
          text: "September",
          align: "center",
          sortable: false,
          value: "september",
        },
        {
          text: "October",
          align: "center",
          sortable: false,
          value: "october",
        },
        {
          text: "November",
          align: "center",
          sortable: false,
          value: "november",
        },
        {
          text: "December",
          align: "center",
          sortable: false,
          value: "december",
        },
        {
          text: "Total",
          align: "center",
          sortable: false,
          value: "total",
        },
      ];
    },
  },
  watch: {
    year: {
      handler() {
        this.getStatistics();
      },
    },
  },
  methods: {
    getStatistics() {
      this.loading = true;
      let url = "/reports/reportPaymentsByCompanies?year=" + this.year;

      getAPI(url)
        .then((res) => {
          this.payments = res.data;
          this.loading = false;
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.loading = false;

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
  mounted() {
    this.getStatistics();
  },
};
</script>
<style lang=""></style>
