<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <template>
      <v-card class="rounded-xl overflow-hidden">
        <v-toolbar class="primary" tag="div" flat dark tile>
          <div class="text-h6">{{ "Statistics" }}</div></v-toolbar
        >
        <v-tabs v-model="tab" center-active show-arrows>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#company">By Companies </v-tab>
          <v-tab href="#employee">By Employees </v-tab>
          <v-tab href="#source">By Source </v-tab>
          <v-tab href="#convert">Converted</v-tab>
          <v-tab href="#laleac">Last Lead Action </v-tab>

          <v-tab href="#statuschanges"> Status Changes </v-tab>
          <v-tab href="#newbussiness"> New Bussiness </v-tab>
          <v-tab href="#payments"> Payments </v-tab>
          <v-tab href="#duplicateclients"> Duplicate Clients </v-tab>
          <v-tab href="#duplicateleads"> Duplicate Leads </v-tab>
          <v-tab href="#calls"> Calls </v-tab>
          <v-tab href="#sms"> SMS </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item value="company">
            <statistic />
          </v-tab-item>
          <v-tab-item value="employee">
            <statistics-employee />
          </v-tab-item>
          <v-tab-item value="source">
            <statistics-source />
          </v-tab-item>
          <v-tab-item value="convert">
            <statistics-converted />
          </v-tab-item>
          <v-tab-item value="laleac">
            <statistics-last-action />
          </v-tab-item>
          <v-tab-item value="statuschanges">
            <statistics-change-status />
          </v-tab-item>
          <v-tab-item value="newbussiness">
            <new-business />
          </v-tab-item>
          <v-tab-item value="payments">
            <payment-statistic />
          </v-tab-item>
          <v-tab-item value="duplicateclients">
            <duplicate-clients />
          </v-tab-item>
          <v-tab-item value="duplicateleads">
            <duplicate-leads />
          </v-tab-item>
          <v-tab-item value="calls">
            <statistics-calls />
          </v-tab-item>
          <v-tab-item value="sms"> <StatisticsSMS /> </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </div>
</template>
<script>
import Statistic from "@/views/Statistics/Statistic.vue";

import StatisticsChangeStatus from "@/views/Statistics/StatisticsChangeStatus.vue";
import NewBusiness from "@/views/Statistics/NewBusiness.vue";
import StatisticsEmployee from "@/views/Statistics/StatisticsEmployee.vue";
import PaymentStatistic from "@/views/Statistics/PaymentStatistic.vue";
import DuplicateClients from "@/views/Statistics/DuplicateClients.vue";
import DuplicateLeads from "@/views/Statistics/DuplicateLeads.vue";
import StatisticsCalls from "@/views/Statistics/StatisticsCalls.vue";
import StatisticsSource from "@/views/Statistics/StatisticsSource.vue";
import StatisticsLastAction from "./StatisticsLastAction.vue";
import StatisticsConverted from "./StatisticsConverted.vue";

export default {
  components: {
    Statistic,
    StatisticsChangeStatus,
    NewBusiness,
    StatisticsEmployee,
    PaymentStatistic,
    DuplicateClients,
    DuplicateLeads,
    StatisticsCalls,
    StatisticsSMS: () => import("@/views/Statistics/StatisticsSMS.vue"),
    StatisticsSource,
    StatisticsLastAction,
    StatisticsConverted,
  },
  name: "main-statistics",

  data() {
    return {
      tab: null,
    };
  },
};
</script>
<style lang="scss" scoped></style>
