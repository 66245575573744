var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-container',[_c('div',{staticClass:"d-flex justify-end mt-2 mx-4"},[(_vm.dateInterval == 'custom')?[_c('v-row',{staticClass:"mx-2",attrs:{"no-gutters":""}},[_c('v-col',[_c('ma-date-picker',{attrs:{"label":"From","past":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{staticClass:"ml-2"},[_c('ma-date-picker',{attrs:{"label":"To","past":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)]:_vm._e(),_c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","loading":_vm.loading,"depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-tune")]),_vm._v(" Filter ")],1)]}}])},[_c('v-list',{attrs:{"width":"140","nav":"","dense":""}},[_vm._l((_vm.intervals),function(interval){return _c('v-list-item',{key:interval.value,attrs:{"link":""},on:{"click":function($event){return _vm.setIntervar(interval.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(interval.label))])],1)}),_c('v-divider'),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.dateInterval = 'custom'}}},[_c('v-list-item-title',[_vm._v("Select")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setIntervar('this-year')}}},[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Clean")])],1)],2)],1)],1)],2),_c('v-layout',{staticClass:"pa-4",attrs:{"column":""}},[_c('div',{staticClass:"mr-2 text-capitalize"},[_vm._v(" "+_vm._s(_vm.dateInterval.replace("-", " "))+" ")]),_c('v-layout',{staticClass:"text-body-1 text--secondary",attrs:{"column":""}},[_c('div',[_vm._v(" From "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDate(_vm.query.date1)))])]),_c('div',[_vm._v(" To "),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.prettyDate(_vm.query.date2)))])])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.statusNoCero,"options":_vm.options,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.showDetails},scopedSlots:_vm._u([{key:"item.beforePolizeStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1 font-weight-bold",style:({ color: _vm.colorShow(item.beforePolizeStatus) })},[_vm._v(" "+_vm._s(_vm.statusShow(item.beforePolizeStatus))+" ")])]}},{key:"item.polizeStatus",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1 font-weight-bold",style:({ color: _vm.colorShow(item.polizeStatus) })},[_vm._v(" "+_vm._s(_vm.statusShow(item.polizeStatus))+" ")])]}}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?[_c('statistics-status',{attrs:{"finalStatus":_vm.statusFinal,"initialStatus":_vm.statusInital,"dateFrom":_vm.query.date1,"dateTo":_vm.query.date2},on:{"closedialog":_vm.closeDialog}})]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }